import {
  RichText,
  HeroMedia,
  SEO,
  SEOProps,
  HeroMediaType
} from '@islandsstofa/react'
import Img from 'gatsby-image'
import React from 'react'

export default {
  PrismicNewsBodyRichtext: {
    component: RichText,
    dataToProps(data: any) {
      return {
        html: data.primary.content.html
      }
    }
  },

  PrismicNewsBodySeo: {
    component: SEO,
    dataToProps: (data: any): SEOProps => ({
      title: data.primary.override_title,
      description: data.primary.override_description,
      image: data.primary.override_image,
      keywords: data.primary.override_keywords
    })
  },

  PrismicNewsBodyHeroMedia: {
    component: HeroMedia,
    dataToProps(data: any): HeroMediaType {
      const {
        primary: {
          caption,
          single_image: singleImage,
          video,
          firstonpage: firstOnPage
        },
        items: gallery
      } = data

      return {
        caption,
        singleImage,
        video,
        gallery,
        firstOnPage,
        onRenderImageComponent: () => (
          <Img
            fluid={singleImage.fluid}
            alt={singleImage.alt}
            className='image-rounded'
          />
        )
      }
    }
  }
}
