import React from 'react'
import { Draft } from '@kogk/gatsby-theme-prismic'
import page from '@cmp/templates/Page'
import news from '@cmp/templates/News'

const typeMap = {
  page,
  news
}

export default () => <Draft typeMap={typeMap} />
