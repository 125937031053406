import React from 'react'
import { graphql } from 'gatsby'
import { usePreview, makeSlices } from '@kogk/gatsby-theme-prismic'
import '@src/data/prismic-fragments/news'
import MainLayout from '@root/src/components/layout/Main'
import sliceMapping from '@src/data/prismic-slice-mapping/news-slices'
import { GlobalDataProvider } from '@src/data/global-data'
import useGetNews from '@src/hooks/useGetNews'
import { NewsSlider } from '@cmp/site/CardSlider'
import { Helmet } from 'react-helmet'
import ArticleFootnote from '@cmp/site/ArticleFootnote'

const Slices = makeSlices(sliceMapping)

type Props = { data: { staticData: any } }

const News = ({ data: staticData }: Props) => {
  const data = usePreview(staticData)

  const language = data.prismicNews.lang.substring(0, 2)

  const { prismicNews } = data

  const allNews = useGetNews()

  const otherNews = allNews.filter(n => n.prismicId !== prismicNews.prismicId)

  return (
    <>
      <GlobalDataProvider language={language}>
        <MainLayout>
          <article className='page-wrap news'>
            <Slices body={prismicNews.data.body} />
            <ArticleFootnote />
            <NewsSlider
              cards={otherNews}
              title='Aðrar fréttir'
              link_text='Sjá allar fréttir'
              link_url='/frettir'
            />
          </article>
        </MainLayout>
      </GlobalDataProvider>
      <Helmet>
        <title>
          {prismicNews.data.title.text
            ? `${prismicNews.data.title.text} | `
            : ''}
          Grænvangur
        </title>
      </Helmet>
    </>
  )
}

export default News

export const query = graphql`
  query genericNewsQuery($prismicId: ID!) {
    prismicNews(prismicId: { eq: $prismicId }) {
      ...prismicNewsFragmentFull
    }
  }
`
